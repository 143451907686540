import React from 'react';
import { TemplateIcon } from '@assets/icons';
import { Api } from '@citrite/sf-api';
import { configureTemplatesClient } from '@data/clients';
import {
	getDefaultDrawerProps,
	getDefaultModalProps,
	SaveAsTemplateOverlay,
	SelectTemplateOverlay,
	TemplateDetailsOverlay,
	UseTemplateOverlay,
} from '@overlays';
import { templatesListPath } from '@routes';
import {
	internalTemplatesPiletOverlays,
	templatePiletBlocks,
	templatesPiletOverlays,
} from '@sdk/extensionTypes';
import { PiletApi } from '@sharefiledev/sharefile-appshell';
import { t, withDefaultProviders } from '@utils';
import { Skeleton } from 'antd';
import { AsyncBlockDevelopmentPage } from './blockDevelopment';
import { FeatureFlag } from './featureFlagDefinitions';
import { setLogger } from './logger';
import {
	checkRolesAndPreferences,
	RolesAndProvisioningRequirements,
} from './provisioning';

const LazyRenderConditionally = React.lazy(
	() => import('./provisioning/RenderConditionally')
);
const AsyncLazyRenderConditionally = props => (
	<React.Suspense fallback={<Skeleton />}>
		<LazyRenderConditionally {...props} />
	</React.Suspense>
);

const LazyManagementPage = React.lazy(() => import('./pages/ManagementPage'));
const AsyncLazyManagementPage = () => (
	<React.Suspense fallback={<Skeleton />}>
		<LazyManagementPage />
	</React.Suspense>
);

const LazyCreateFromSpecificTemplateButton = React.lazy(
	() => import('./components/CreateFromSpecificTemplateButton')
);
const AsyncLazyCreateFromSpecificTemplateButton = props => (
	<React.Suspense fallback={<Skeleton />}>
		<LazyCreateFromSpecificTemplateButton {...props} />
	</React.Suspense>
);

const LazyCreateFromTemplateWithTagButton = React.lazy(
	() => import('./components/CreateFromTemplateWithTagButton')
);
const AsyncLazyCreateFromTemplateWithTagButton = props => (
	<React.Suspense fallback={<Skeleton />}>
		<LazyCreateFromTemplateWithTagButton {...props} />
	</React.Suspense>
);

const getRolesProvisioningRequirements = () => {
	const requirements: RolesAndProvisioningRequirements = {
		requiredRoles: ['Employee'],
		requiredFeatureFlags: [FeatureFlag.EnableTemplateManagement],
		requiredASTs: ['EnableTemplates'],
	};

	return requirements;
};

export function setup(piletApi: PiletApi) {
	setLogger(piletApi.sf.getLogger());
	configureApiClient(piletApi);
	registerNavigationEntries(piletApi);
	registerPages(piletApi);
	registerOverlays(piletApi);
	registerInternalOverlays(piletApi);
	registerExtensions(piletApi);
	if (process.env.NODE_ENV === 'development') {
		registerDeveloperTools(piletApi);
	}
}

function configureApiClient(piletApi: PiletApi) {
	configureTemplatesClient(piletApi);
	piletApi.sf.registerSfApiConfigHandler(Api.configure as unknown as any); // type mismatch between sf-api and appshell
}

function registerNavigationEntries(piletApi: PiletApi) {
	piletApi.sf.registerLeftNavComponent({
		href: templatesListPath,
		title: () => t('templates-pilet:titles.dashboard') ?? '...',
		isAvailable: () =>
			checkRolesAndPreferences(piletApi, getRolesProvisioningRequirements()),
		weight: 200,
		icon: TemplateIcon,
	});
}

function registerPages(piletApi: PiletApi) {
	piletApi.registerPage(
		templatesListPath,
		withDefaultProviders(piletApi, props => (
			<AsyncLazyRenderConditionally
				{...props}
				piletApi={piletApi}
				requirements={getRolesProvisioningRequirements()}
				redirectToDashboard
			>
				<AsyncLazyManagementPage />
			</AsyncLazyRenderConditionally>
		))
	);
}

function registerOverlays(piletApi: PiletApi) {
	piletApi.registerModal(
		templatesPiletOverlays.selectTemplate,
		withDefaultProviders(piletApi, props => (
			<SelectTemplateOverlay {...props} {...props.options} />
		)),
		getDefaultModalProps({
			isFullscreen: true,
			titleText: t('prompts.select_a_template'),
		})
	);

	piletApi.registerModal(
		templatesPiletOverlays.useTemplate,
		withDefaultProviders(piletApi, props => (
			<UseTemplateOverlay {...props} {...props.options} />
		)),
		getDefaultModalProps({
			isFullscreen: true,
			titleText: t('prompts.use_a_template'),
		})
	);

	piletApi.registerDrawer(
		templatesPiletOverlays.useTemplateDrawer,
		withDefaultProviders(piletApi, props => (
			<UseTemplateOverlay
				{...props}
				templateRID={props.options.params.templateRID}
				containerRID={props.options.params.containerRID}
				resourceType={props.options.params.resourceType}
				onCompleteNavigationPath={props.options.params.onCompleteNavigationPath}
				tags={props.options.params.tags}
				context={props.options.params.context}
				buttonText={props.options.params.buttonText}
			/>
		)),
		getDefaultDrawerProps({
			titleText: t('prompts.use_a_template'),
		})
	);

	piletApi.registerModal(
		templatesPiletOverlays.saveAsTemplate,
		withDefaultProviders(piletApi, props => (
			<SaveAsTemplateOverlay {...props} {...props.options} />
		)),
		getDefaultModalProps({
			isFullscreen: false,
			titleText: t('prompts.save_as_template'),
		})
	);
}

function registerInternalOverlays(piletApi: PiletApi) {
	piletApi.registerDrawer(
		internalTemplatesPiletOverlays.templateDetails,
		withDefaultProviders(piletApi, props => (
			<TemplateDetailsOverlay {...props} {...props.options} />
		)),
		getDefaultDrawerProps({
			titleText: t('prompts.use_a_template'),
			width: '736px', // override large size
		})
	);
}

function registerExtensions(piletApi: PiletApi) {
	piletApi.registerExtension(
		templatePiletBlocks.createFromSpecificTemplateButtonBlock,
		({ params }) => (
			<AsyncLazyCreateFromSpecificTemplateButton piletApi={piletApi} {...params} />
		)
	);
	piletApi.registerExtension(
		templatePiletBlocks.createFromTemplateWithTagButtonBlock,
		({ params }) => (
			<AsyncLazyCreateFromTemplateWithTagButton piletApi={piletApi} {...params} />
		)
	);
}

function registerDeveloperTools(piletApi: PiletApi) {
	piletApi.sf.registerLeftNavComponent({
		href: '/templates-block-development',
		title: () => 'Templates Block Dev',
		icon: TemplateIcon,
		weight: 50,
	});

	piletApi.registerPage('/templates-block-development', () => (
		<AsyncBlockDevelopmentPage piletApi={piletApi} />
	));
}
