/* tslint:disable */
/* This file was generated by @citrite/translate. Manual changes will be lost. */
import { Language } from '@citrite/translate';
export type locale = 'de' | 'en' | 'es' | 'fr' | 'it' | 'ja' | 'nl' | 'pt-BR';
export const languages: Language[] = [
  {
    "key": "de",
    "content": {
      "common": {
        "actions": "Aktionen",
        "all_categories": "Alle Kategorien",
        "back": "Zurück",
        "close": "Schließen",
        "create": "Erstellen",
        "created_by": "Erstellt von",
        "created_on": "Erstellt am",
        "delete": "Löschen",
        "description": "Beschreibung",
        "details": "Details",
        "edit": "Bearbeiten",
        "more": "Mehr",
        "my_templates": "Eigene Vorlagen",
        "name": "Name",
        "optional": "Optional",
        "preview": "Vorschau",
        "search": "Suchen",
        "select": "Auswählen",
        "shared_with_me": "Für mich freigegeben",
        "template": "Vorlage",
        "templates": "Vorlagen",
        "unknown": "unbekannt"
      },
      "messages": {
        "browse_catalog": "Katalog durchsuchen",
        "browse_catalog_description": "Durchsuchen Sie den Katalog nach weiteren Vorlagen.",
        "couldnt_load_template": "Wir konnten Ihre Vorlage nicht laden.",
        "couldnt_load_templates": "Wir konnten Ihre Vorlagen nicht laden.",
        "create_resource": "Erstellen {{resourceTypeLabel}}",
        "created_template": "Erstellte Vorlage",
        "draft_state": "Ihre Vorlage wurde erstellt, befindet sich jedoch aufgrund möglicher Probleme beim Abrufen von Ressourcen derzeit im Entwurfstatus.",
        "delete_failure_multiple": "Ihre Vorlagen konnten nicht gelöscht werden",
        "delete_failure_single": "Die Vorlage {{name}} konnte nicht gelöscht werden",
        "delete_success_multiple": "{{count}} Vorlagen gelöscht",
        "delete_success_single": "Gelöschte Vorlage",
        "editing_parameters": "Wählen Sie beliebige Parameterwerte aus oder geben Sie sie ein, um zu beginnen. Diese Werte werden nur als Beispieldaten verwendet, wenn Sie Ihre Vorlage anpassen.",
        "empty": {
          "error_loading_templates": {
            "title": "Ihre Vorlagen konnten nicht geladen werden"
          },
          "no_category_results": {
            "title": "Sie haben keine Vorlagen vom Typ {{resourceType}}"
          },
          "no_search_results": {
            "title": "Ihre Suche stimmt mit keiner Vorlage überein",
            "subtitle": "{{term}} konnte weder in Titeln noch in Beschreibungen von Vorlagen gefunden werden.",
            "action_label": "Suche löschen"
          },
          "my_templates": {
            "title": "Eigene Vorlagen",
            "subtitle": "Sie haben noch keine Vorlagen gespeichert."
          },
          "my_templates_ftu": {
            "title": "Willkommen in Ihrer Vorlagenbibliothek",
            "subtitle": "Beginnen Sie mit dem Einrichten Ihrer Bibliothek, indem Sie eine Vorlage erstellen.",
            "action_message": "Weitere Vorlagenoptionen werden demnächst bereitgestellt"
          },
          "shared_templates": {
            "title": "Freigegebene Vorlagen",
            "subtitle": "Hier finden Sie Vorlagen, die andere Benutzer erstellt haben."
          },
          "shared_templates_ftu": {
            "title": "Freigegebene Vorlagen",
            "subtitle": "Hier finden Sie Vorlagen, die andere Benutzer erstellt haben."
          }
        },
        "template_run_failed": "Wir konnten Ihr {{resourceTypeLabel}} nicht erstellen. Bitte versuchen Sie es erneut.",
        "template_run_success": "{{resourceTypeLabel}} erstellt",
        "generic_error": "Es ist ein Fehler aufgetreten.",
        "loading_template": "Die Vorlagendetails werden geladen.",
        "loading_templates": "Ihre Vorlagen werden geladen.",
        "no_templates_available": "Es sind keine Vorlagen verfügbar.",
        "run_manually": "Sie können diese Vorlage manuell mit der folgenden RID ausführen: ${RID}",
        "unable_to_create_template": "Ihre Vorlage konnte nicht erstellt werden. Versuchen Sie es erneut.",
        "unable_to_redirect": "Das Navigieren zu {{resourceTypeLabel}} war nicht möglich",
        "what_is_copied": "Was kopiert wird",
        "what_is_not_copied": "Was nicht kopiert wird"
      },
      "permissions": {
        "doc_template": {
          "delete": "Kann löschen",
          "modify": "Kann ändern",
          "presets": {
            "contributor": "Mitwirkender",
            "creator": "Ersteller",
            "viewer": "Leseberechtigter"
          },
          "share": "Kann freigeben",
          "source": "Für diese Vorlage",
          "view": "Kann anzeigen"
        },
        "template": {
          "all_permissions": "Volle Kontrolle",
          "delete": "Kann die Vorlage löschen",
          "read": "Kann die Vorlage verwenden",
          "share": "Kann den Zugriff auf die Vorlage verwalten"
        }
      },
      "prompts": {
        "create_template": "Vorlage erstellen",
        "delete_confirmation_multiple": "Möchten Sie {{count}} Vorlagen wirklich löschen? Diese Vorlagen werden dauerhaft gelöscht. Wenn diese Vorlagen freigegeben werden, verlieren Benutzer den Zugriff.",
        "delete_confirmation_single": "Möchten Sie die Vorlage {{name}} wirklich löschen? Die Vorlage wird dauerhaft gelöscht. Wenn diese Vorlage freigegeben wurde, verlieren die Benutzer ihren Zugriff.",
        "delete_template": "Vorlage löschen",
        "duplicate": "Duplizieren",
        "edit_template": "Vorlage bearbeiten",
        "manage_access": "Zugriff verwalten",
        "save_as_template": "Als Vorlage speichern",
        "select_a_template": "Vorlage auswählen",
        "use_template": "Vorlage verwenden",
        "use_a_template": "Vorlage verwenden",
        "view_details": "Details anzeigen"
      },
      "resource": {
        "type_template": "Vorlage vom Typ {{type}}",
        "sharefile_datatable": {
          "category_label": "Tabelle",
          "label": "Tabelle",
          "preview": "Tabellendetails",
          "save_as_template": {
            "copied": [
              "Data table structure"
            ],
            "not_copied": [
              "Data in the data table"
            ],
            "top_level_description": "Speichern Sie diese Datentabelle als Vorlage, die zum Erstellen neuer Tabellen verwendet werden kann."
          }
        },
        "sharefile_document": {
          "category_label": "Dokument",
          "label": "Dokument",
          "preview": "Dokumentdetails"
        },
        "sharefile_flow": {
          "category_label": "Workflows",
          "label": "Workflow",
          "preview": "Workflowdetails",
          "save_as_template": {
            "copied": [
              "Workflow definition (including all configuration details and trigger variables)"
            ],
            "not_copied": [
              "Active status",
              "Last updated by",
              "Run history"
            ],
            "top_level_description": "Speichern Sie diesen Workflow als Vorlage, die zum Erstellen neuer Workflows verwendet werden kann."
          }
        },
        "sharefile_inforeq": {
          "category_label": "Informationsanforderungen",
          "label": "Informationsanforderung",
          "preview": "Details zur Informationsanforderung",
          "save_as_template": {
            "copied": [
              "The form with all its fields"
            ],
            "not_copied": [
              "Any users assigned to the request",
              "Any responses to the request",
              "The due date for the request"
            ],
            "top_level_description": "Speichern Sie diese Informationsanforderung als Vorlage, die Sie zum Erstellen neuer Anforderungen verwenden können."
          }
        },
        "sharefile_project": {
          "category_label": "Projekte",
          "label": "Projekt",
          "preview": "Projektdetails",
          "save_as_template": {
            "copied": [
              "Document requests (without due dates, assignees, or statuses)",
              "Tasks (without due dates, assignees, or statuses)"
            ],
            "not_copied": [
              "Team members and clients on the project",
              "Comments",
              "Files"
            ],
            "top_level_description": "Speichern Sie dieses Projekt als Vorlage, die zum Erstellen neuer Projekte verwendet werden kann."
          }
        },
        "sharefile_requestlist": {
          "category_label": "Dokumentanforderungen",
          "label": "Dokumentanforderung",
          "preview": "Details der Dokumentanforderung",
          "save_as_template": {
            "copied": [
              "Document request items (without due dates, assignees, statuses, or files)"
            ],
            "not_copied": [
              "Assigned users",
              "Comments",
              "Due dates",
              "Files"
            ],
            "top_level_description": "Speichern Sie diese Dokumentanforderung als Vorlage, die Sie zum Erstellen neuer Dokumentanforderungen verwenden können."
          }
        }
      },
      "titles": {
        "dashboard": "Vorlagen"
      }
    }
  },
  {
    "key": "en",
    "content": {
      "common": {
        "actions": "Actions",
        "all_categories": "All categories",
        "back": "Back",
        "cancel": "Cancel",
        "change": "Change",
        "close": "Close",
        "copy": "Copy",
        "create": "Create",
        "created_by": "Created by",
        "created_on": "Created on",
        "delete": "Delete",
        "description": "Description",
        "details": "Details",
        "duplicate_and_edit": "Duplicate and edit",
        "duplicate": "Duplicate",
        "duplicating": "Duplicating",
        "edit": "Edit",
        "more": "More",
        "my_templates": "My templates",
        "name": "Name",
        "optional": "optional",
        "preview": "Preview",
        "save": "Save",
        "search": "Search",
        "select": "Select",
        "shared_with_me": "Shared with me",
        "solution_templates": "Solution templates",
        "template": "Template",
        "templates": "Templates",
        "unknown": "Unknown"
      },
      "messages": {
        "browse_catalog": "Browse catalog",
        "browse_catalog_description": "Browse the catalog for more templates.",
        "couldnt_load_template": "We couldn't load your template.",
        "couldnt_load_templates": "We couldn't load your templates.",
        "create_resource": "Create {{resourceTypeLabel}}",
        "created_template": "Created template",
        "draft_state": "Your template was created, but is currently in a draft state due to potential issues with resource fetching.",
        "delete_failure_multiple": "We couldn't delete your templates",
        "delete_failure_single": "We couldn't delete the {{name}} template",
        "delete_success_multiple": "Deleted {{count}} templates",
        "delete_success_single": "Deleted template",
        "editing_parameters": "Select or enter any parameter values to get started. These values will only be used as sample data as you customize your template.",
        "empty": {
          "error_loading_templates": {
            "title": "We couldn’t load your templates"
          },
          "no_category_results": {
            "title": "You don't have any {{resourceType}} templates"
          },
          "no_search_results": {
            "title": "Your search doesn’t match any templates",
            "subtitle": "We couldn't find {{term}} in any template titles or descriptions.",
            "action_label": "Clear search"
          },
          "my_templates": {
            "title": "You haven't created any applicable templates yet",
            "subtitle": "Templates you create will live here."
          },
          "my_templates_ftu": {
            "title": "Welcome to your template library",
            "subtitle": "Start to build your library by creating a template.",
            "action_message": "More template options coming soon"
          },
          "shared_templates": {
            "title": "You do not have any applicable shared templates yet",
            "subtitle": "Templates created by others and shared with you will live here."
          },
          "shared_templates_ftu": {
            "title": "Shared templates",
            "subtitle": "Here you’ll be able to find templates that other users have created."
          },
          "installed_templates": {
            "title": "You do not have any applicable installed templates yet",
            "subtitle": "Templates installed from the catalog that can be used will live here.",
            "action_label": "Go to catalog"
          },
          "installed_templates_ftu": {
            "title": "Installed templates",
            "subtitle": "Templates you install from the catalog will live here.",
            "action_label": "Go to catalog"
          }
        },
        "template_run_failed": "We couldn't create your {{resourceTypeLabel}}. Please try again.",
        "template_run_success": "Created {{resourceTypeLabel}}",
        "generic_error": "An error occurred.",
        "loading_template": "Template details are loading.",
        "loading_templates": "Your templates are loading.",
        "no_templates_available": "There are no templates available.",
        "run_manually": "You can run this template manually using the following RID: ${RID}",
        "unable_to_create_template": "We couldn't create your template. Please try again.",
        "unable_to_redirect": "We couldn't navigate to your {{resourceTypeLabel}}",
        "what_is_copied": "What will be copied",
        "what_is_not_copied": "What won't be copied",
        "template_copied": "Template duplicated",
        "template_copy_error": "We couldn't duplicate your template"
      },
      "permissions": {
        "doc_template": {
          "delete": "Can delete",
          "modify": "Can modify",
          "presets": {
            "contributor": "Contributor",
            "creator": "Creator",
            "viewer": "Viewer"
          },
          "share": "Can share",
          "source": "For this template",
          "view": "Can view"
        },
        "template": {
          "all_permissions": "Full control",
          "delete": "Can delete the template",
          "read": "Can use the template",
          "share": "Can manage access to the template"
        }
      },
      "prompts": {
        "choose_a_template": "Choose a template",
        "create_template": "Create template",
        "delete_confirmation_multiple": "Are you sure you want to delete {{count}} templates? These templates will be permanently deleted. If these templates are shared, users will lose access.",
        "delete_confirmation_single": "Are you sure you want to delete the {{name}} template? The template will be permanently deleted. If this template is shared, users will lose access.",
        "delete_template": "Delete template",
        "duplicate": "Duplicate",
        "edit_template": "Edit template",
        "manage_access": "Manage access",
        "save_as_template": "Save as template",
        "select_a_template": "Select a template",
        "use_template": "Use template",
        "use_a_template": "Use a template",
        "view_details": "View details"
      },
      "resource": {
        "type_template": "{{type}} template",
        "sharefile_datatable": {
          "category_label": "Table",
          "label": "table",
          "preview": "Table details",
          "save_as_template": {
            "copied": [
              "Data table structure"
            ],
            "not_copied": [
              "Data in the data table"
            ],
            "top_level_description": "Save this data table as a template that can be used to create new tables."
          }
        },
        "sharefile_document": {
          "category_label": "Document",
          "label": "document",
          "preview": "Document details"
        },
        "sharefile_flow": {
          "category_label": "Workflows",
          "label": "workflow",
          "preview": "Workflow details",
          "save_as_template": {
            "copied": [
              "Workflow definition (including all configuration details and trigger variables)"
            ],
            "not_copied": [
              "Active status",
              "Last updated by",
              "Run history"
            ],
            "top_level_description": "Save this workflow as a template that can be used to create new workflows."
          }
        },
        "sharefile_inforeq": {
          "category_label": "Information requests",
          "label": "information request",
          "preview": "Information Request details",
          "save_as_template": {
            "copied": [
              "The form with all its fields"
            ],
            "not_copied": [
              "Any users assigned to the request",
              "Any responses to the request",
              "The due date for the request"
            ],
            "top_level_description": "Save this information request as a template that can be used to create new requests."
          }
        },
        "sharefile_project": {
          "category_label": "Projects",
          "label": "project",
          "preview": "Project details",
          "save_as_template": {
            "copied": [
              "Document requests (without due dates, assignees, or statuses)",
              "Tasks (without due dates, assignees, or statuses)"
            ],
            "not_copied": [
              "Team members and clients on the project",
              "Comments",
              "Files"
            ],
            "top_level_description": "Save this project as a template that can be used to create new projects."
          }
        },
        "sharefile_requestlist": {
          "category_label": "Document requests",
          "label": "document request",
          "preview": "Document request details",
          "save_as_template": {
            "copied": [
              "Document request items (without due dates, assignees, statuses, or files)"
            ],
            "not_copied": [
              "Assigned users",
              "Comments",
              "Due dates",
              "Files"
            ],
            "top_level_description": "Save this document request as a template that can be used to create new document requests."
          }
        }
      },
      "titles": {
        "dashboard": "Templates"
      }
    }
  },
  {
    "key": "es",
    "content": {
      "common": {
        "actions": "Acciones",
        "all_categories": "Todas las categorias",
        "back": "Atrás",
        "close": "Cerrar",
        "create": "Crear",
        "created_by": "Creado por",
        "created_on": "Creadas el",
        "delete": "Eliminar",
        "description": "Descripción",
        "details": "Detalles",
        "edit": "Modificar",
        "more": "Más",
        "my_templates": "Mis plantillas",
        "name": "Nombre",
        "optional": "opcional",
        "preview": "Vista previa",
        "search": "Buscar",
        "select": "Seleccionar",
        "shared_with_me": "Compartidos conmigo",
        "template": "Plantilla",
        "templates": "Plantillas",
        "unknown": "desconocido"
      },
      "messages": {
        "browse_catalog": "Examinar catálogo",
        "browse_catalog_description": "Examine el catálogo en busca de otras plantillas.",
        "couldnt_load_template": "No pudimos cargar su plantilla.",
        "couldnt_load_templates": "No pudimos cargar sus plantillas.",
        "create_resource": "Crear {{resourceTypeLabel}}",
        "created_template": "Plantilla creada",
        "draft_state": "La plantilla se creó, pero actualmente se encuentra en estado de borrador debido a posibles problemas con la obtención de recursos.",
        "delete_failure_multiple": "No pudimos eliminar sus plantillas",
        "delete_failure_single": "No pudimos eliminar la plantilla {{name}}",
        "delete_success_multiple": "{{count}} plantillas eliminadas",
        "delete_success_single": "Plantilla eliminada",
        "editing_parameters": "Seleccione o introduzca los valores de los parámetros para empezar. Estos valores solo se usarán como datos de muestra a medida que personalice su plantilla.",
        "empty": {
          "error_loading_templates": {
            "title": "No pudimos cargar sus plantillas"
          },
          "no_category_results": {
            "title": "No tiene ninguna plantilla de {{resourceType}}"
          },
          "no_search_results": {
            "title": "Su búsqueda no coincide con ninguna plantilla",
            "subtitle": "No pudimos encontrar {{term}} en los títulos o descripciones de ninguna plantilla.",
            "action_label": "Borrar búsqueda"
          },
          "my_templates": {
            "title": "Mis plantillas",
            "subtitle": "Aún no tiene ninguna plantilla guardada."
          },
          "my_templates_ftu": {
            "title": "Le damos la bienvenida a su biblioteca de plantillas",
            "subtitle": "Cree una plantilla para empezar a construir su biblioteca.",
            "action_message": "Próximamente habrá más opciones de plantilla"
          },
          "shared_templates": {
            "title": "Plantillas compartidas",
            "subtitle": "Aquí podrá encontrar plantillas que otros usuarios han creado."
          },
          "shared_templates_ftu": {
            "title": "Plantillas compartidas",
            "subtitle": "Aquí podrá encontrar plantillas que otros usuarios han creado."
          }
        },
        "template_run_failed": "No pudimos crear su {{resourceTypeLabel}}. Inténtelo de nuevo.",
        "template_run_success": "Se creó {{resourceTypeLabel}}",
        "generic_error": "Se ha producido un error.",
        "loading_template": "Se están cargando los detalles de la plantilla.",
        "loading_templates": "Sus plantillas se están cargando.",
        "no_templates_available": "No hay plantillas disponibles.",
        "run_manually": "Puede ejecutar esta plantilla manualmente con el siguiente RID: ${RID}",
        "unable_to_create_template": "No pudimos crear su plantilla. Inténtelo de nuevo.",
        "unable_to_redirect": "No pudimos ir a su {{resourceTypeLabel}}",
        "what_is_copied": "Lo que se copiará",
        "what_is_not_copied": "Lo que no se copiará"
      },
      "permissions": {
        "doc_template": {
          "delete": "Puede eliminar",
          "modify": "Puede modificar",
          "presets": {
            "contributor": "Colaborador",
            "creator": "Creador",
            "viewer": "Participante"
          },
          "share": "Puede compartir",
          "source": "Para esta plantilla",
          "view": "Puede ver"
        },
        "template": {
          "all_permissions": "Control total",
          "delete": "Puede eliminar la plantilla",
          "read": "Puede usar la plantilla",
          "share": "Puede administrar el acceso a la plantilla"
        }
      },
      "prompts": {
        "create_template": "Crear plantilla",
        "delete_confirmation_multiple": "¿Seguro que quiere eliminar {{count}} plantillas? Estas plantillas se eliminarán de forma permanente. Si estas plantillas se comparten, los usuarios no podrán acceder.",
        "delete_confirmation_single": "¿Seguro que quiere eliminar la plantilla {{name}}? La plantilla se eliminará de forma permanente. Si esta plantilla se comparte, los usuarios no podrán acceder.",
        "delete_template": "Eliminar plantilla",
        "duplicate": "Duplicar",
        "edit_template": "Modificar plantilla",
        "manage_access": "Administrar acceso",
        "save_as_template": "Guardar como plantilla",
        "select_a_template": "Seleccionar una plantilla",
        "use_template": "Usar plantilla",
        "use_a_template": "Usar una plantilla",
        "view_details": "Ver detalles"
      },
      "resource": {
        "type_template": "Plantilla {{type}}",
        "sharefile_datatable": {
          "category_label": "Tabla",
          "label": "tabla",
          "preview": "Detalles de la tabla",
          "save_as_template": {
            "copied": [
              "Data table structure"
            ],
            "not_copied": [
              "Data in the data table"
            ],
            "top_level_description": "Guarde esta tabla de datos como plantilla que puede usar para crear tablas."
          }
        },
        "sharefile_document": {
          "category_label": "Documento",
          "label": "documento",
          "preview": "Detalles del documento"
        },
        "sharefile_flow": {
          "category_label": "Flujos de trabajo",
          "label": "flujo de trabajo",
          "preview": "Detalles del flujo de trabajo",
          "save_as_template": {
            "copied": [
              "Workflow definition (including all configuration details and trigger variables)"
            ],
            "not_copied": [
              "Active status",
              "Last updated by",
              "Run history"
            ],
            "top_level_description": "Guarde este flujo de trabajo como plantilla que puede usar para crear flujos de trabajo."
          }
        },
        "sharefile_inforeq": {
          "category_label": "Solicitudes de información",
          "label": "solicitud de información",
          "preview": "Detalles de la solicitud de información",
          "save_as_template": {
            "copied": [
              "The form with all its fields"
            ],
            "not_copied": [
              "Any users assigned to the request",
              "Any responses to the request",
              "The due date for the request"
            ],
            "top_level_description": "Guarde esta solicitud de información como plantilla que puede usar para crear solicitudes."
          }
        },
        "sharefile_project": {
          "category_label": "Proyectos",
          "label": "proyecto",
          "preview": "Detalles del proyecto",
          "save_as_template": {
            "copied": [
              "Document requests (without due dates, assignees, or statuses)",
              "Tasks (without due dates, assignees, or statuses)"
            ],
            "not_copied": [
              "Team members and clients on the project",
              "Comments",
              "Files"
            ],
            "top_level_description": "Guarde este proyecto como plantilla que puede usar para crear proyectos."
          }
        },
        "sharefile_requestlist": {
          "category_label": "Solicitudes de documentos",
          "label": "solicitud de documento",
          "preview": "Detalles de la solicitud de documento",
          "save_as_template": {
            "copied": [
              "Document request items (without due dates, assignees, statuses, or files)"
            ],
            "not_copied": [
              "Assigned users",
              "Comments",
              "Due dates",
              "Files"
            ],
            "top_level_description": "Guarde esta solicitud de documento como plantilla que puede usar para crear solicitudes de documentos."
          }
        }
      },
      "titles": {
        "dashboard": "Plantillas"
      }
    }
  },
  {
    "key": "fr",
    "content": {
      "common": {
        "actions": "Actions",
        "all_categories": "Toutes les catégories",
        "back": "Précédent",
        "close": "Fermer",
        "create": "Créer",
        "created_by": "Créé par",
        "created_on": "Créé le",
        "delete": "Supprimer",
        "description": "Description",
        "details": "Détails",
        "edit": "Modifier",
        "more": "Plus",
        "my_templates": "Mes modèles",
        "name": "Nom",
        "optional": "facultatif",
        "preview": "Aperçu",
        "search": "Rechercher",
        "select": "Sélectionner",
        "shared_with_me": "Partagés avec moi",
        "template": "Modèle",
        "templates": "Modèles",
        "unknown": "inconnu"
      },
      "messages": {
        "browse_catalog": "Parcourir le catalogue",
        "browse_catalog_description": "Parcourez le catalogue pour rechercher d'autres modèles.",
        "couldnt_load_template": "Nous n'avons pas pu charger votre modèle.",
        "couldnt_load_templates": "Nous n'avons pas pu charger vos modèles.",
        "create_resource": "Créer {{resourceTypeLabel}}",
        "created_template": "Modèle créé",
        "draft_state": "Le modèle a bien été créé, mais il est actuellement à l'état de brouillon en raison de problèmes potentiels liés à la récupération des ressources.",
        "delete_failure_multiple": "Impossible de supprimer vos modèles",
        "delete_failure_single": "Impossible de supprimer le modèle {{name}}",
        "delete_success_multiple": "{{count}} modèles supprimés",
        "delete_success_single": "Modèle supprimé",
        "editing_parameters": "Pour commencer, sélectionnez ou entrez des valeurs de paramètre. Elles serviront uniquement d'exemples de données lorsque vous personnaliserez votre modèle.",
        "empty": {
          "error_loading_templates": {
            "title": "Nous n'avons pas pu charger vos modèles"
          },
          "no_category_results": {
            "title": "Vous ne disposez d'aucun modèle de type {{resourceType}}"
          },
          "no_search_results": {
            "title": "Aucun modèle ne correspond à votre recherche",
            "subtitle": "Nous n'avons trouvé le terme {{term}} dans aucun titre ni description de modèle.",
            "action_label": "Effacer la recherche"
          },
          "my_templates": {
            "title": "Mes modèles",
            "subtitle": "Vous n'avez pas encore enregistré de modèles."
          },
          "my_templates_ftu": {
            "title": "Bienvenue dans votre bibliothèque de modèles",
            "subtitle": "Commencez à construire votre bibliothèque en créant un modèle.",
            "action_message": "Davantage d'options de modèle bientôt disponibles"
          },
          "shared_templates": {
            "title": "Modèles partagés",
            "subtitle": "Ici vous trouverez des modèles créés par d'autres utilisateurs."
          },
          "shared_templates_ftu": {
            "title": "Modèles partagés",
            "subtitle": "Ici vous trouverez des modèles créés par d'autres utilisateurs."
          }
        },
        "template_run_failed": "Nous n'avons pas pu créer votre {{resourceTypeLabel}}. Réessayez.",
        "template_run_success": "Création de {{resourceTypeLabel}}",
        "generic_error": "Une erreur s’est produite.",
        "loading_template": "Les détails du modèle sont en cours de chargement.",
        "loading_templates": "Vos modèles sont en cours de chargement.",
        "no_templates_available": "Aucun modèle n'est disponible.",
        "run_manually": "Vous pouvez exécuter ce modèle manuellement à l'aide de l'identifiant RID suivant : ${RID}",
        "unable_to_create_template": "Nous n'avons pas pu créer votre modèle. Réessayez.",
        "unable_to_redirect": "Nous n'avons pas pu accéder à votre {{resourceTypeLabel}}",
        "what_is_copied": "Ce qui sera copié",
        "what_is_not_copied": "Ce qui ne sera pas copié"
      },
      "permissions": {
        "doc_template": {
          "delete": "Peut supprimer",
          "modify": "Peut modifier",
          "presets": {
            "contributor": "Contributeur",
            "creator": "Créateur",
            "viewer": "Lecteur"
          },
          "share": "Peut partager",
          "source": "Pour ce modèle",
          "view": "Peut afficher"
        },
        "template": {
          "all_permissions": "Contrôle complet",
          "delete": "Peut supprimer le modèle",
          "read": "Peut utiliser le modèle",
          "share": "Peut gérer les accès au modèle"
        }
      },
      "prompts": {
        "create_template": "Créer un modèle",
        "delete_confirmation_multiple": "Êtes-vous sûr de vouloir supprimer {{count}} modèles ? Ces modèles seront définitivement supprimés et s'ils sont partagés, les utilisateurs n'y auront plus accès.",
        "delete_confirmation_single": "Êtes-vous sûr de vouloir supprimer le modèle {{name}} ? Ce modèle sera définitivement supprimé et s'il est partagé, les utilisateurs n'y auront plus accès.",
        "delete_template": "Supprimer le modèle",
        "duplicate": "Dupliquer",
        "edit_template": "Modifier le modèle",
        "manage_access": "Gérer les accès",
        "save_as_template": "Enregistrer en tant que modèle",
        "select_a_template": "Sélectionner un modèle",
        "use_template": "Utiliser le modèle",
        "use_a_template": "Utiliser un modèle",
        "view_details": "Afficher les détails"
      },
      "resource": {
        "type_template": "Modèle de type {{type}}",
        "sharefile_datatable": {
          "category_label": "Tableau",
          "label": "tableau",
          "preview": "Détails du tableau",
          "save_as_template": {
            "copied": [
              "Data table structure"
            ],
            "not_copied": [
              "Data in the data table"
            ],
            "top_level_description": "Enregistrez cette table de données en tant que modèle pouvant être utilisé pour créer d'autres tables."
          }
        },
        "sharefile_document": {
          "category_label": "Document",
          "label": "document",
          "preview": "Détails du document"
        },
        "sharefile_flow": {
          "category_label": "Workflows",
          "label": "workflow",
          "preview": "Détails du workflow",
          "save_as_template": {
            "copied": [
              "Workflow definition (including all configuration details and trigger variables)"
            ],
            "not_copied": [
              "Active status",
              "Last updated by",
              "Run history"
            ],
            "top_level_description": "Enregistrez ce workflow en tant que modèle pouvant être utilisé pour créer d'autres workflows."
          }
        },
        "sharefile_inforeq": {
          "category_label": "Requêtes d'information",
          "label": "requête d'informations",
          "preview": "Détails de la requête d'informations",
          "save_as_template": {
            "copied": [
              "The form with all its fields"
            ],
            "not_copied": [
              "Any users assigned to the request",
              "Any responses to the request",
              "The due date for the request"
            ],
            "top_level_description": "Enregistrez cette demande d'information en tant que modèle réutilisable pour créer d'autres demandes."
          }
        },
        "sharefile_project": {
          "category_label": "Projets",
          "label": "projet",
          "preview": "Détails du projet",
          "save_as_template": {
            "copied": [
              "Document requests (without due dates, assignees, or statuses)",
              "Tasks (without due dates, assignees, or statuses)"
            ],
            "not_copied": [
              "Team members and clients on the project",
              "Comments",
              "Files"
            ],
            "top_level_description": "Enregistrez ce projet en tant que modèle pouvant être utilisé pour créer d'autres projets."
          }
        },
        "sharefile_requestlist": {
          "category_label": "Demandes de documents",
          "label": "demande de document",
          "preview": "Détails de la demande de document",
          "save_as_template": {
            "copied": [
              "Document request items (without due dates, assignees, statuses, or files)"
            ],
            "not_copied": [
              "Assigned users",
              "Comments",
              "Due dates",
              "Files"
            ],
            "top_level_description": "Enregistrez cette demande de document en tant que modèle réutilisable pour créer d'autres demandes de document."
          }
        }
      },
      "titles": {
        "dashboard": "Modèles"
      }
    }
  },
  {
    "key": "it",
    "content": {
      "common": {
        "actions": "Azioni",
        "all_categories": "Tutte le categorie",
        "back": "Indietro",
        "close": "Chiudi",
        "create": "Crea",
        "created_by": "Creato da",
        "created_on": "Data di creazione",
        "delete": "Elimina",
        "description": "Descrizione",
        "details": "Dettagli",
        "edit": "Modifica",
        "more": "Altro",
        "my_templates": "Modelli personali",
        "name": "Nome",
        "optional": "facoltativo",
        "preview": "Anteprima",
        "search": "Cerca",
        "select": "Seleziona",
        "shared_with_me": "Condiviso con me",
        "template": "Modello",
        "templates": "Modelli",
        "unknown": "sconosciuto"
      },
      "messages": {
        "browse_catalog": "Sfoglia il catalogo",
        "browse_catalog_description": "Sfogliare il catalogo per trovare ulteriori modelli.",
        "couldnt_load_template": "Non è stato possibile caricare il modello.",
        "couldnt_load_templates": "Non è stato possibile caricare i modelli.",
        "create_resource": "Crea {{resourceTypeLabel}}",
        "created_template": "Modello creato",
        "draft_state": "Il modello è stato creato, ma è attualmente in stato di bozza a causa di potenziali problemi di recupero delle risorse.",
        "delete_failure_multiple": "Non è stato caricare caricare i modelli",
        "delete_failure_single": "Non è stato possibile eliminare il modello {{name}}",
        "delete_success_multiple": "{{count}} modelli eliminati",
        "delete_success_single": "Modello eliminato",
        "editing_parameters": "Selezionare o inserire i valori dei parametri per iniziare. Questi valori verranno utilizzati solo come dati di esempio durante la personalizzazione del modello.",
        "empty": {
          "error_loading_templates": {
            "title": "Non è stato possibile caricare i modelli"
          },
          "no_category_results": {
            "title": "Non si dispone di modelli {{resourceType}}"
          },
          "no_search_results": {
            "title": "La ricerca non trova corrispondenze in alcun modello",
            "subtitle": "Non siamo riusciti a trovare {{term}} nei titoli o nelle descrizioni dei modelli.",
            "action_label": "Cancella ricerca"
          },
          "my_templates": {
            "title": "Modelli personali",
            "subtitle": "Non sono ancora presenti modelli salvati."
          },
          "my_templates_ftu": {
            "title": "Benvenuto nella libreria di modelli",
            "subtitle": "Iniziare a costruire la libreria creando un modello.",
            "action_message": "Sono in arrivo altre opzioni di modelli"
          },
          "shared_templates": {
            "title": "Modelli condivisi",
            "subtitle": "Qui si potranno trovare i modelli creati da altri utenti."
          },
          "shared_templates_ftu": {
            "title": "Modelli condivisi",
            "subtitle": "Qui si potranno trovare i modelli creati da altri utenti."
          }
        },
        "template_run_failed": "Non siamo riusciti a creare il tuo {{resourceTypeLabel}}. Si prega di riprovare.",
        "template_run_success": "Creazione di {{resourceTypeLabel}} completata",
        "generic_error": "Si è verificato un errore.",
        "loading_template": "I dettagli del modello sono in fase di caricamento.",
        "loading_templates": "I modelli sono in fase di caricamento.",
        "no_templates_available": "Non ci sono modelli disponibili.",
        "run_manually": "È possibile eseguire questo modello manualmente utilizzando il seguente RID: ${RID}",
        "unable_to_create_template": "Non è stato possibile creare il modello. Riprovare.",
        "unable_to_redirect": "Non è stato possibile passare a {{resourceTypeLabel}}",
        "what_is_copied": "Cosa verrà copiato",
        "what_is_not_copied": "Cosa non verrà copiato"
      },
      "permissions": {
        "doc_template": {
          "delete": "Può eliminare",
          "modify": "Può modificare",
          "presets": {
            "contributor": "Collaboratore",
            "creator": "Autore",
            "viewer": "Visualizzatore"
          },
          "share": "Può condividere",
          "source": "Per questo modello",
          "view": "Può visualizzare"
        },
        "template": {
          "all_permissions": "Pieno controllo",
          "delete": "Può eliminare il modello",
          "read": "Può utilizzare il modello",
          "share": "Può gestire l'accesso al modello"
        }
      },
      "prompts": {
        "create_template": "Crea modello",
        "delete_confirmation_multiple": "Eliminare i modelli {{count}}? Questi modelli saranno eliminati definitivamente. Se questi modelli sono condivisi, gli utenti perderanno l'accesso.",
        "delete_confirmation_single": "Eliminare il modello {{name}}? Il modello verrà eliminato definitivamente. Se questo modello è condiviso, gli utenti perderanno l'accesso.",
        "delete_template": "Elimina modello",
        "duplicate": "Duplica",
        "edit_template": "Modifica modello",
        "manage_access": "Gestisci l'accesso",
        "save_as_template": "Salva come modello",
        "select_a_template": "Seleziona un modello",
        "use_template": "Usa modello",
        "use_a_template": "Utilizza un modello",
        "view_details": "Visualizza dettagli"
      },
      "resource": {
        "type_template": "Modello {{type}}",
        "sharefile_datatable": {
          "category_label": "Tabella",
          "label": "tabella",
          "preview": "Dettagli tabella",
          "save_as_template": {
            "copied": [
              "Data table structure"
            ],
            "not_copied": [
              "Data in the data table"
            ],
            "top_level_description": "Salvare questa tabella di dati come modello che può essere utilizzato per creare nuovi flussi di lavoro."
          }
        },
        "sharefile_document": {
          "category_label": "Documento",
          "label": "documento",
          "preview": "Dettagli documento"
        },
        "sharefile_flow": {
          "category_label": "Flussi di lavoro",
          "label": "flusso di lavoro",
          "preview": "Dettagli del flusso di lavoro",
          "save_as_template": {
            "copied": [
              "Workflow definition (including all configuration details and trigger variables)"
            ],
            "not_copied": [
              "Active status",
              "Last updated by",
              "Run history"
            ],
            "top_level_description": "Salvare questo flusso di lavoro come modello che può essere utilizzato per creare nuovi flussi di lavoro."
          }
        },
        "sharefile_inforeq": {
          "category_label": "Richieste di informazioni",
          "label": "richiesta di informazioni",
          "preview": "Dettagli della richiesta di informazioni",
          "save_as_template": {
            "copied": [
              "The form with all its fields"
            ],
            "not_copied": [
              "Any users assigned to the request",
              "Any responses to the request",
              "The due date for the request"
            ],
            "top_level_description": "Salvare questa richiesta di informazioni come modello che può essere utilizzato per creare nuove richieste."
          }
        },
        "sharefile_project": {
          "category_label": "Progetti",
          "label": "progetto",
          "preview": "Dettagli del progetto",
          "save_as_template": {
            "copied": [
              "Document requests (without due dates, assignees, or statuses)",
              "Tasks (without due dates, assignees, or statuses)"
            ],
            "not_copied": [
              "Team members and clients on the project",
              "Comments",
              "Files"
            ],
            "top_level_description": "Salvare il progetto come modello che può essere utilizzato per creare nuovi progetti."
          }
        },
        "sharefile_requestlist": {
          "category_label": "Richieste di documenti",
          "label": "richiesta di documenti",
          "preview": "Dettagli della richiesta di documenti",
          "save_as_template": {
            "copied": [
              "Document request items (without due dates, assignees, statuses, or files)"
            ],
            "not_copied": [
              "Assigned users",
              "Comments",
              "Due dates",
              "Files"
            ],
            "top_level_description": "Salvare questa richiesta di documento come modello che può essere utilizzato per creare nuove richieste di documenti."
          }
        }
      },
      "titles": {
        "dashboard": "Modelli"
      }
    }
  },
  {
    "key": "ja",
    "content": {
      "common": {
        "actions": "操作",
        "all_categories": "すべてのカテゴリ",
        "back": "戻る",
        "close": "閉じる",
        "create": "作成",
        "created_by": "作成者",
        "created_on": "作成日:",
        "delete": "削除",
        "description": "説明",
        "details": "詳細",
        "edit": "編集",
        "more": "詳細",
        "my_templates": "自分のテンプレート",
        "name": "名前",
        "optional": "オプション",
        "preview": "プレビュー",
        "search": "検索",
        "select": "選択",
        "shared_with_me": "ユーザーと共有",
        "template": "テンプレート",
        "templates": "テンプレート",
        "unknown": "不明"
      },
      "messages": {
        "browse_catalog": "カタログを参照する",
        "browse_catalog_description": "その他のテンプレートについてはカタログを参照してください。",
        "couldnt_load_template": "テンプレートを読み込めませんでした。",
        "couldnt_load_templates": "テンプレートを読み込めませんでした。",
        "create_resource": "{{resourceTypeLabel}} を作成する",
        "created_template": "作成済みのテンプレート",
        "draft_state": "テンプレートは作成されましたが、リソースの取得に問題が発生する可能性があるため、現在は下書き状態です。",
        "delete_failure_multiple": "テンプレートを削除できませんでした",
        "delete_failure_single": "{{name}} のテンプレートを削除できませんでした",
        "delete_success_multiple": "{{count}} 個のテンプレートを削除しました",
        "delete_success_single": "テンプレートを削除しました",
        "editing_parameters": "開始するには、任意のパラメーター値を選択または入力します。これらの値は、テンプレートをカスタマイズするときにサンプル データとしてのみ使用されます。",
        "empty": {
          "error_loading_templates": {
            "title": "テンプレートを読み込めませんでした"
          },
          "no_category_results": {
            "title": "{{resourceType}} テンプレートがありません"
          },
          "no_search_results": {
            "title": "検索条件に一致するテンプレートはありません",
            "subtitle": "テンプレートのタイトルまたは説明に {{term}} が見つかりませんでした。",
            "action_label": "検索をクリア"
          },
          "my_templates": {
            "title": "自分のテンプレート",
            "subtitle": "保存されたテンプレートはまだありません。"
          },
          "my_templates_ftu": {
            "title": "テンプレート ライブラリへようこそ",
            "subtitle": "テンプレートを作成してライブラリの構築を開始しましょう。",
            "action_message": "テンプレート オプションの追加を予定しています"
          },
          "shared_templates": {
            "title": "共有されたテンプレート",
            "subtitle": "他のユーザーが作成したテンプレートをここで見つけることができます。"
          },
          "shared_templates_ftu": {
            "title": "共有されたテンプレート",
            "subtitle": "他のユーザーが作成したテンプレートをここで見つけることができます。"
          }
        },
        "template_run_failed": "{{resourceTypeLabel}} を作成できませんでした。再試行してください。",
        "template_run_success": "作成された {{resourceTypeLabel}}",
        "generic_error": "エラーが発生しました。",
        "loading_template": "テンプレートの詳細を読み込み中です。",
        "loading_templates": "テンプレートを読み込み中です。",
        "no_templates_available": "利用可能なテンプレートはありません。",
        "run_manually": "次の RID を使用して、このテンプレートを手動で実行できます: ${RID}",
        "unable_to_create_template": "テンプレートを作成できませんでした。再試行してください。",
        "unable_to_redirect": "{{resourceTypeLabel}} に移動できませんでした",
        "what_is_copied": "コピーされる内容",
        "what_is_not_copied": "コピーされない内容"
      },
      "permissions": {
        "doc_template": {
          "delete": "削除できる",
          "modify": "変更できる",
          "presets": {
            "contributor": "貢献者",
            "creator": "作成者",
            "viewer": "閲覧者"
          },
          "share": "共有できる",
          "source": "このテンプレートの場合",
          "view": "表示できる"
        },
        "template": {
          "all_permissions": "フル コントロール",
          "delete": "テンプレートを削除できる",
          "read": "テンプレートを使用できる",
          "share": "テンプレートへのアクセスを管理できる"
        }
      },
      "prompts": {
        "create_template": "テンプレートを作成する",
        "delete_confirmation_multiple": "{{count}} 個のテンプレートを削除してもよろしいですか? これらのテンプレートは完全に削除されるため、共有されている場合、ユーザーはアクセスできなくなります。",
        "delete_confirmation_single": "{{name}} テンプレートを削除してもよろしいですか? このテンプレートは完全に削除されるため、共有されている場合、ユーザーはアクセスできなくなります。",
        "delete_template": "テンプレートを削除する",
        "duplicate": "複製",
        "edit_template": "テンプレートの編集",
        "manage_access": "アクセスを管理する",
        "save_as_template": "テンプレートとして保存する",
        "select_a_template": "テンプレートを選択する",
        "use_template": "テンプレートを使用する",
        "use_a_template": "テンプレートを使用",
        "view_details": "詳細を表示"
      },
      "resource": {
        "type_template": "{{type}} テンプレート",
        "sharefile_datatable": {
          "category_label": "表",
          "label": "表",
          "preview": "表の詳細",
          "save_as_template": {
            "copied": [
              "Data table structure"
            ],
            "not_copied": [
              "Data in the data table"
            ],
            "top_level_description": "このデータ テーブルを、新しいテーブルの作成に使用できるテンプレートとして保存します。"
          }
        },
        "sharefile_document": {
          "category_label": "ドキュメント",
          "label": "ドキュメント",
          "preview": "ドキュメントの詳細"
        },
        "sharefile_flow": {
          "category_label": "ワークフロー",
          "label": "ワークフロー",
          "preview": "ワークフローの詳細",
          "save_as_template": {
            "copied": [
              "Workflow definition (including all configuration details and trigger variables)"
            ],
            "not_copied": [
              "Active status",
              "Last updated by",
              "Run history"
            ],
            "top_level_description": "このワークフローを、新しいワークフローの作成に使用できるテンプレートとして保存します。"
          }
        },
        "sharefile_inforeq": {
          "category_label": "情報リクエスト",
          "label": "情報リクエスト",
          "preview": "情報リクエストの詳細",
          "save_as_template": {
            "copied": [
              "The form with all its fields"
            ],
            "not_copied": [
              "Any users assigned to the request",
              "Any responses to the request",
              "The due date for the request"
            ],
            "top_level_description": "この情報リクエストを、新しいリクエストの作成に使用できるテンプレートとして保存します。"
          }
        },
        "sharefile_project": {
          "category_label": "プロジェクト",
          "label": "プロジェクト",
          "preview": "プロジェクトの詳細",
          "save_as_template": {
            "copied": [
              "Document requests (without due dates, assignees, or statuses)",
              "Tasks (without due dates, assignees, or statuses)"
            ],
            "not_copied": [
              "Team members and clients on the project",
              "Comments",
              "Files"
            ],
            "top_level_description": "このプロジェクトを、新しいプロジェクトの作成に使用できるテンプレートとして保存します。"
          }
        },
        "sharefile_requestlist": {
          "category_label": "ドキュメント リクエスト",
          "label": "ドキュメント リクエスト",
          "preview": "ドキュメント リクエストの詳細",
          "save_as_template": {
            "copied": [
              "Document request items (without due dates, assignees, statuses, or files)"
            ],
            "not_copied": [
              "Assigned users",
              "Comments",
              "Due dates",
              "Files"
            ],
            "top_level_description": "このドキュメント リクエストを、新しいドキュメント リクエストの作成に使用できるテンプレートとして保存します。"
          }
        }
      },
      "titles": {
        "dashboard": "テンプレート"
      }
    }
  },
  {
    "key": "nl",
    "content": {
      "common": {
        "actions": "Acties",
        "all_categories": "Alle categorieën",
        "back": "Terug",
        "close": "Sluiten",
        "create": "Maken",
        "created_by": "Gemaakt door",
        "created_on": "Gemaakt op",
        "delete": "Verwijderen",
        "description": "Beschrijving",
        "details": "Details",
        "edit": "Bewerken",
        "more": "Meer",
        "my_templates": "Mijn sjablonen",
        "name": "Naam",
        "optional": "optioneel",
        "preview": "Voorbeeldweergave",
        "search": "Zoeken",
        "select": "Selecteren",
        "shared_with_me": "Gedeeld met mij",
        "template": "Sjabloon",
        "templates": "Sjablonen",
        "unknown": "onbekend"
      },
      "messages": {
        "browse_catalog": "Door catalogus bladeren",
        "browse_catalog_description": "Blader door de catalogus voor meer sjablonen.",
        "couldnt_load_template": "We kunnen uw sjabloon niet laden.",
        "couldnt_load_templates": "We kunnen uw sjablonen niet laden.",
        "create_resource": "{{resourceTypeLabel}} maken",
        "created_template": "Sjabloon gemaakt",
        "draft_state": "Uw sjabloon is gemaakt, maar bevindt zich momenteel in een conceptstatus vanwege mogelijke problemen met het ophalen van bronnen.",
        "delete_failure_multiple": "We kunnen uw sjablonen niet verwijderen",
        "delete_failure_single": "We konden de sjabloon {{name}} niet verwijderen",
        "delete_success_multiple": "{{count}} sjablonen verwijderd",
        "delete_success_single": "Sjabloon verwijderd",
        "editing_parameters": "Selecteer of voer parameterwaarden in om aan de slag te gaan. Deze waarden worden alleen gebruikt als voorbeeldgegevens wanneer u uw sjabloon aanpast.",
        "empty": {
          "error_loading_templates": {
            "title": "We kunnen uw sjablonen niet laden"
          },
          "no_category_results": {
            "title": "U hebt geen {{resourceType}}-sjablonen"
          },
          "no_search_results": {
            "title": "Uw zoekopdracht komt met geen enkele sjabloon overeen",
            "subtitle": "We hebben {{term}} niet gevonden in sjabloontitels of -beschrijvingen.",
            "action_label": "Zoekopdracht wissen"
          },
          "my_templates": {
            "title": "Mijn sjablonen",
            "subtitle": "U hebt nog geen opgeslagen sjablonen."
          },
          "my_templates_ftu": {
            "title": "Welkom bij uw sjablonenbibliotheek",
            "subtitle": "Begin uw bibliotheek op te bouwen door een sjabloon te maken.",
            "action_message": "Binnenkort meer sjabloonopties beschikbaar"
          },
          "shared_templates": {
            "title": "Gedeelde sjablonen",
            "subtitle": "Hier kunt u sjablonen vinden die andere gebruikers hebben gemaakt."
          },
          "shared_templates_ftu": {
            "title": "Gedeelde sjablonen",
            "subtitle": "Hier kunt u sjablonen vinden die andere gebruikers hebben gemaakt."
          }
        },
        "template_run_failed": "Wij kunnen uw {{resourceTypeLabel}} niet maken. Probeer het opnieuw.",
        "template_run_success": "{{resourceTypeLabel}} gemaakt",
        "generic_error": "Er is een fout opgetreden.",
        "loading_template": "Sjabloongegevens worden geladen.",
        "loading_templates": "Uw sjablonen worden geladen.",
        "no_templates_available": "Er zijn geen sjablonen beschikbaar.",
        "run_manually": "U kunt dit sjabloon handmatig uitvoeren met behulp van de volgende RID: ${RID}",
        "unable_to_create_template": "Wij kunnen uw sjabloon niet maken. Probeer het opnieuw.",
        "unable_to_redirect": "We kunnen niet naar uw {{resourceTypeLabel}} gaan",
        "what_is_copied": "Wat er wordt gekopieerd",
        "what_is_not_copied": "Wat er niet wordt gekopieerd"
      },
      "permissions": {
        "doc_template": {
          "delete": "Kan verwijderen",
          "modify": "Kan wijzigen",
          "presets": {
            "contributor": "Bijdrager",
            "creator": "Auteur",
            "viewer": "Waarnemer"
          },
          "share": "Kan delen",
          "source": "Voor dit sjabloon",
          "view": "Kan bekijken"
        },
        "template": {
          "all_permissions": "Volledige controle",
          "delete": "Kan het documentsjabloon verwijderen",
          "read": "Kan het sjabloon gebruiken",
          "share": "Kan de toegang tot het sjabloon beheren"
        }
      },
      "prompts": {
        "create_template": "Sjabloon maken",
        "delete_confirmation_multiple": "Weet u zeker dat u {{count}} sjablonen wilt verwijderen? Deze sjablonen worden permanent verwijderd. Als deze sjablonen worden gedeeld, verliezen gebruikers de toegang.",
        "delete_confirmation_single": "Weet u zeker dat u het sjabloon {{name}} wilt verwijderen? Het sjabloon wordt permanent verwijderd. Als dit sjabloon wordt gedeeld, verliezen gebruikers de toegang.",
        "delete_template": "Sjabloon verwijderen",
        "duplicate": "Duplicaat",
        "edit_template": "Sjabloon bewerken",
        "manage_access": "Toegang beheren",
        "save_as_template": "Opslaan als sjabloon",
        "select_a_template": "Selecteer een sjabloon",
        "use_template": "Sjabloon gebruiken",
        "use_a_template": "Een sjabloon gebruiken",
        "view_details": "Details weergeven"
      },
      "resource": {
        "type_template": "{{type}}-sjabloon",
        "sharefile_datatable": {
          "category_label": "Tabel",
          "label": "tabel",
          "preview": "Tabelgegevens",
          "save_as_template": {
            "copied": [
              "Data table structure"
            ],
            "not_copied": [
              "Data in the data table"
            ],
            "top_level_description": "Sla deze gegegvenstabel op als een sjabloon die kan worden gebruikt om nieuwe tabellen te maken."
          }
        },
        "sharefile_document": {
          "category_label": "Document",
          "label": "document",
          "preview": "Documentgegevens"
        },
        "sharefile_flow": {
          "category_label": "Werkstromen",
          "label": "werkstroom",
          "preview": "Details werkstroom",
          "save_as_template": {
            "copied": [
              "Workflow definition (including all configuration details and trigger variables)"
            ],
            "not_copied": [
              "Active status",
              "Last updated by",
              "Run history"
            ],
            "top_level_description": "Sla dit project op als een sjabloon die kan worden gebruikt om nieuwe werkstromen te maken."
          }
        },
        "sharefile_inforeq": {
          "category_label": "Informatieaanvragen",
          "label": "informatieaanvraag",
          "preview": "Gegevens van informatieaanvraag",
          "save_as_template": {
            "copied": [
              "The form with all its fields"
            ],
            "not_copied": [
              "Any users assigned to the request",
              "Any responses to the request",
              "The due date for the request"
            ],
            "top_level_description": "Sla deze informatieaanvraag op als een sjabloon die kan worden gebruikt om nieuwe aanvragen te maken."
          }
        },
        "sharefile_project": {
          "category_label": "Projecten",
          "label": "project",
          "preview": "Details van project",
          "save_as_template": {
            "copied": [
              "Document requests (without due dates, assignees, or statuses)",
              "Tasks (without due dates, assignees, or statuses)"
            ],
            "not_copied": [
              "Team members and clients on the project",
              "Comments",
              "Files"
            ],
            "top_level_description": "Sla dit project op als een sjabloon die kan worden gebruikt om nieuwe projecten te maken."
          }
        },
        "sharefile_requestlist": {
          "category_label": "Documentaanvragen",
          "label": "documentaanvraag",
          "preview": "Details van documentaanvraag",
          "save_as_template": {
            "copied": [
              "Document request items (without due dates, assignees, statuses, or files)"
            ],
            "not_copied": [
              "Assigned users",
              "Comments",
              "Due dates",
              "Files"
            ],
            "top_level_description": "Sla deze documentaanvraag op als een sjabloon die kan worden gebruikt om nieuwe documentaanvragen te maken."
          }
        }
      },
      "titles": {
        "dashboard": "Sjablonen"
      }
    }
  },
  {
    "key": "pt-BR",
    "content": {
      "common": {
        "actions": "Ações",
        "all_categories": "Todas as categorias",
        "back": "Voltar",
        "close": "Fechar",
        "create": "Criar",
        "created_by": "Criado por",
        "created_on": "Criado em",
        "delete": "Excluir",
        "description": "Descrição",
        "details": "Detalhes",
        "edit": "Editar",
        "more": "Mais",
        "my_templates": "Meus modelos",
        "name": "Nome",
        "optional": "opcional",
        "preview": "Visualizar",
        "search": "Pesquisar",
        "select": "Selecionar",
        "shared_with_me": "Compartilhado comigo",
        "template": "Modelo",
        "templates": "Modelos",
        "unknown": "desconhecido"
      },
      "messages": {
        "browse_catalog": "Navegar no catálogo",
        "browse_catalog_description": "Navegue pelo catálogo para ver mais modelos.",
        "couldnt_load_template": "Não foi possível carregar o seu modelo.",
        "couldnt_load_templates": "Não foi possível carregar os seus modelos.",
        "create_resource": "Criar {{resourceTypeLabel}}",
        "created_template": "Modelo criado",
        "draft_state": "Seu modelo foi criado, mas atualmente está em estado de rascunho devido a possíveis problemas com a busca de recursos.",
        "delete_failure_multiple": "Não foi possível excluir seus modelos",
        "delete_failure_single": "Não foi possível excluir o modelo {{name}}",
        "delete_success_multiple": "{{count}} modelos excluídos",
        "delete_success_single": "Modelo excluído",
        "editing_parameters": "Selecione ou insira qualquer valor de parâmetro para começar. Esses valores serão usados apenas como dados de amostra conforme você personaliza seu modelo.",
        "empty": {
          "error_loading_templates": {
            "title": "Não foi possível carregar seus modelos"
          },
          "no_category_results": {
            "title": "Você não tem nenhum modelo de {{resourceType}}"
          },
          "no_search_results": {
            "title": "Sua pesquisa não corresponde a nenhum modelo",
            "subtitle": "Não foi possível encontrar {{term}} em nenhum título ou descrição de modelo.",
            "action_label": "Limpar pesquisa"
          },
          "my_templates": {
            "title": "Meus modelos",
            "subtitle": "Você ainda não tem modelos salvos."
          },
          "my_templates_ftu": {
            "title": "Bem-vindo à sua biblioteca de modelos",
            "subtitle": "Comece a criar sua biblioteca criando um modelo.",
            "action_message": "Mais opções de modelos em breve"
          },
          "shared_templates": {
            "title": "Modelos compartilhados",
            "subtitle": "Aqui você poderá encontrar modelos que outros usuários criaram."
          },
          "shared_templates_ftu": {
            "title": "Modelos compartilhados",
            "subtitle": "Aqui você poderá encontrar modelos que outros usuários criaram."
          }
        },
        "template_run_failed": "Não foi possível criar o seu {{resourceTypeLabel}}. Tente novamente.",
        "template_run_success": "{{resourceTypeLabel}} criado",
        "generic_error": "Ocorreu um erro.",
        "loading_template": "Os detalhes do modelo estão sendo carregados.",
        "loading_templates": "Seus modelos estão sendo carregados.",
        "no_templates_available": "Não há modelos disponíveis.",
        "run_manually": "Você pode executar este modelo manualmente usando o seguinte RID: ${RID}",
        "unable_to_create_template": "Não foi possível criar o seu modelo. Tente novamente.",
        "unable_to_redirect": "Não foi possível navegar para seu {{resourceTypeLabel}}",
        "what_is_copied": "O que será copiado",
        "what_is_not_copied": "O que não será copiado"
      },
      "permissions": {
        "doc_template": {
          "delete": "Pode excluir",
          "modify": "Pode modificar",
          "presets": {
            "contributor": "Colaborador",
            "creator": "Criador",
            "viewer": "Observador"
          },
          "share": "Pode compartilhar",
          "source": "Para este modelo",
          "view": "Pode exibir"
        },
        "template": {
          "all_permissions": "Controle total",
          "delete": "Pode excluir o modelo",
          "read": "Pode usar o modelo",
          "share": "Pode gerenciar o acesso ao modelo"
        }
      },
      "prompts": {
        "create_template": "Criar modelo",
        "delete_confirmation_multiple": "Tem certeza de que deseja excluir {{count}} modelos? Esses modelos serão excluídos permanentemente. Se esses modelos forem compartilhados, os usuários perderão o acesso.",
        "delete_confirmation_single": "Tem certeza de que deseja excluir o modelo {{name}}? O modelo será excluído permanentemente. Se esse modelo for compartilhado, os usuários perderão o acesso.",
        "delete_template": "Excluir modelo",
        "duplicate": "Duplicar",
        "edit_template": "Editar modelo",
        "manage_access": "Gerenciar acesso",
        "save_as_template": "Salvar como modelo",
        "select_a_template": "Selecione um modelo",
        "use_template": "Usar modelo",
        "use_a_template": "Usar um modelo",
        "view_details": "Exibir detalhes"
      },
      "resource": {
        "type_template": "Modelo de {{type}}",
        "sharefile_datatable": {
          "category_label": "Tabela",
          "label": "tabela",
          "preview": "Detalhes da tabela",
          "save_as_template": {
            "copied": [
              "Data table structure"
            ],
            "not_copied": [
              "Data in the data table"
            ],
            "top_level_description": "Salve esta tabela de dados como um modelo que pode ser usado para criar novas tabelas."
          }
        },
        "sharefile_document": {
          "category_label": "Documento",
          "label": "documento",
          "preview": "Detalhes do documento"
        },
        "sharefile_flow": {
          "category_label": "Fluxos de trabalho",
          "label": "fluxo de trabalho",
          "preview": "Detalhes de fluxo de trabalho",
          "save_as_template": {
            "copied": [
              "Workflow definition (including all configuration details and trigger variables)"
            ],
            "not_copied": [
              "Active status",
              "Last updated by",
              "Run history"
            ],
            "top_level_description": "Salvar este fluxo de trabalho como um modelo que pode ser usado para criar novos fluxos de trabalho."
          }
        },
        "sharefile_inforeq": {
          "category_label": "Solicitações de informações",
          "label": "solicitação de informações",
          "preview": "Detalhes da solicitação de informações",
          "save_as_template": {
            "copied": [
              "The form with all its fields"
            ],
            "not_copied": [
              "Any users assigned to the request",
              "Any responses to the request",
              "The due date for the request"
            ],
            "top_level_description": "Salve esta solicitação de informações como um modelo que pode ser usado para criar novas solicitações."
          }
        },
        "sharefile_project": {
          "category_label": "Projetos",
          "label": "projeto",
          "preview": "Detalhes do projeto",
          "save_as_template": {
            "copied": [
              "Document requests (without due dates, assignees, or statuses)",
              "Tasks (without due dates, assignees, or statuses)"
            ],
            "not_copied": [
              "Team members and clients on the project",
              "Comments",
              "Files"
            ],
            "top_level_description": "Salvar este projeto como um modelo que pode ser usado para criar novos projetos."
          }
        },
        "sharefile_requestlist": {
          "category_label": "Solicitações de documentos",
          "label": "solicitação de documento",
          "preview": "Detalhes da solicitação de documento",
          "save_as_template": {
            "copied": [
              "Document request items (without due dates, assignees, statuses, or files)"
            ],
            "not_copied": [
              "Assigned users",
              "Comments",
              "Due dates",
              "Files"
            ],
            "top_level_description": "Salve esta solicitação de documento como um modelo que pode ser usado para criar novas solicitações de documentos."
          }
        }
      },
      "titles": {
        "dashboard": "Modelos"
      }
    }
  }
];