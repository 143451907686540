import {
	OverlayDrawerOptions,
	OverlayModalOptions,
} from '@sharefiledev/sharefile-appshell';

export type DefaultModalConfiguration = {
	isFullscreen?: boolean;
	titleText?: string;
};

export type DefaultDrawerConfiguration = {
	titleText?: string;
	width?: string;
};

const fullScreenStyles = {
	styles: {
		body: {
			height: 'calc(95vh - 86px)',
			margin: '20px 0px',
			overflowY: 'auto',
			overflowX: 'hidden',
		},
	},
};

const defaultScreenStyles = {
	styles: {
		body: {
			marginTop: '20px',
			overflowY: 'auto',
			overflowX: 'hidden',
		},
	},
};

export function getDefaultModalProps(
	props: DefaultModalConfiguration
): Partial<OverlayModalOptions<any>> {
	return {
		modalProps: {
			destroyOnClose: true,
			width: props.isFullscreen ? '95vw' : '640px',
			title: props.titleText,
			footer: null,
			centered: !!props.isFullscreen,
			...(props.isFullscreen ? fullScreenStyles : defaultScreenStyles),
		},
	};
}

export function getDefaultDrawerProps(
	props: DefaultDrawerConfiguration
): Partial<OverlayDrawerOptions<any>> {
	return {
		drawerProps: {
			title: props.titleText,
			placement: 'right',
			size: 'large',
			width: props.width,
		},
	};
}

export type SubDrawerConfiguration = DefaultDrawerConfiguration & {
	open: boolean;
	onClose: () => void;
};

export function getDefaultSubDrawerProps({
	titleText,
	open,
	onClose,
}: SubDrawerConfiguration): Partial<OverlayDrawerOptions<any>> {
	return {
		drawerProps: {
			title: titleText,
			placement: 'right',
			size: 'large',
			open,
			onClose,
		},
	};
}
