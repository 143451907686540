import { PermissionsConfiguration, UserRole } from '@sharefiledev/permissions-pilet';
import { resolveLocaleString } from '@utils';

export const templatePermissionsConfig: PermissionsConfiguration = {
	availablePermissionSets: [
		{
			key: 'template-read',
			description: resolveLocaleString('permissions.template.read'),
			sourceLabel: resolveLocaleString('common.templates'),
			permissions: ['*:read'],
			requiredRoles: [UserRole.Employee],
		},
		{
			key: 'template-share',
			description: resolveLocaleString('permissions.template.share'),
			sourceLabel: resolveLocaleString('common.templates'),
			permissions: ['*:share'],
			requireAllKeys: ['template-read'],
		},
		{
			key: 'template-delete',
			description: resolveLocaleString('permissions.template.delete'),
			sourceLabel: resolveLocaleString('common.templates'),
			permissions: ['*:delete'],
			requireAllKeys: ['template-read'],
		},
	],
	presets: [
		{
			rid: 'sharefile:template_permission_preset:full_access',
			name: resolveLocaleString('permissions.template.all_permissions'),
			permissions: ['*:read', '*:delete', '*:share'],
		},
		{
			rid: 'sharefile:template_permission_preset:read',
			name: resolveLocaleString('prompts.use_template'),
			permissions: ['*:read'],
		},
	],
};

export const docTemplatePermissionsConfig: PermissionsConfiguration = {
	availablePermissionSets: [
		{
			key: 'document-template-view',
			description: resolveLocaleString('permissions.doc_template.view'),
			sourceLabel: resolveLocaleString('permissions.doc_template.source'),
			permissions: ['doctemplate:read'],
		},
		{
			key: 'document-template-modify',
			description: resolveLocaleString('permissions.doc_template.modify'),
			sourceLabel: resolveLocaleString('permissions.doc_template.source'),
			permissions: ['doctemplate:modify'],
			requireOneOfKeys: ['document-template-view'],
		},
		{
			key: 'document-template-share',
			description: resolveLocaleString('permissions.doc_template.share'),
			sourceLabel: resolveLocaleString('permissions.doc_template.source'),
			permissions: ['doctemplate:share'],
			requireOneOfKeys: ['document-template-view'],
		},
		{
			key: 'document-template-delete',
			description: resolveLocaleString('permissions.doc_template.delete'),
			sourceLabel: resolveLocaleString('permissions.doc_template.source'),
			permissions: ['doctemplate:delete'],
			requireOneOfKeys: ['document-template-view'],
		},
	],
	presets: [
		{
			rid: 'sharefile:permission_preset-viewer',
			name: resolveLocaleString('permissions.doc_template.presets.viewer'),
			permissions: ['doctemplate:read'],
		},
		{
			rid: 'sharefile:permission_preset-contributor',
			name: resolveLocaleString('permissions.doc_template.presets.contributor'),
			permissions: ['doctemplate:read', 'doctemplate:modify'],
		},
		{
			rid: 'sharefile:permission_preset-creator',
			name: resolveLocaleString('permissions.doc_template.presets.creator'),
			permissions: [
				'doctemplate:read',
				'doctemplate:create',
				'doctemplate:modify',
				'doctemplate:share',
				'doctemplate:delete',
			],
		},
	],
};
