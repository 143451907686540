import { PiletApi } from '@sharefiledev/sharefile-appshell';
import { getApiBaseUrl } from '../../helpers/getApiBaseUrl';
import { initializeSdk, TemplatesSdk } from './sdk';
import {
	ConstructTemplateParams,
	CopyTemplateParams,
	CopyTemplateResponse,
	CreateRunParams,
	CreateTemplateRunResponse,
	DeleteTemplateParams,
	GetRunLogsParams,
	GetRunLogsResponse,
	GetRunParams,
	GetTemplateParams,
	GetTemplateRunResponse,
	ListRunsParams,
	ListTemplatesParams,
	StartEditParams,
	StartEditResponse,
	TemplateInfo,
} from './types';

/**
 * Wraps the Templates v2 Axios SDK (see ./sdk.ts)
 */
export class TemplatesClient {
	private initializedSDK: TemplatesSdk;

	public Initialize(sdk: TemplatesSdk) {
		this.initializedSDK = sdk;
	}

	public listTemplates(
		params?: Omit<ListTemplatesParams, 'filter' | 'searchTerm' | 'enabled'>
	): Promise<TemplateInfo[]> {
		return this.initializedSDK.listTemplates(params);
	}

	public getTemplate(params: GetTemplateParams): Promise<TemplateInfo> {
		return this.initializedSDK.getTemplate(params);
	}

	public createRun(params: CreateRunParams): Promise<CreateTemplateRunResponse> {
		return this.initializedSDK.createRun(params);
	}

	public deleteTemplate(params: DeleteTemplateParams): Promise<void> {
		return this.initializedSDK.deleteTemplate(params);
	}

	public getRun(params: GetRunParams): Promise<GetTemplateRunResponse> {
		return this.initializedSDK.getRun(params);
	}

	public constructTemplate(params: ConstructTemplateParams): Promise<TemplateInfo> {
		return this.initializedSDK.constructTemplate(params);
	}

	public getRunLogs(params: GetRunLogsParams): Promise<GetRunLogsResponse> {
		return this.initializedSDK.getRunLogs(params);
	}

	public listRuns(params: ListRunsParams): Promise<GetTemplateRunResponse[]> {
		return this.initializedSDK.listRuns(params);
	}

	public startEdit(params: StartEditParams): Promise<StartEditResponse> {
		return this.initializedSDK.startEdit(params);
	}

	public copyTemplate(params: CopyTemplateParams): Promise<CopyTemplateResponse> {
		return this.initializedSDK.copyTemplate(params);
	}
}

export const templatesClient = new TemplatesClient();

export function configureTemplatesClient(app: PiletApi) {
	templatesClient.Initialize(
		initializeSdk({
			baseUrl: getApiBaseUrl(app),
		})
	);
}
