export enum FeatureFlag {
	EnableTemplatesPilet = 'enable-templates-pilet', // Gates all components exported by this Pilet. See PCWA-1603: "Scaffold the Template Pilet".
	EnableSaveAsTemplateExtension = 'enable-save-as-template-extension', // Enables the use of the save as template functionality as an extension
	EnableProjectsSaveAsTemplateExtension = 'enable-projects-save-as-template-extension', // Enables the use of the save as template functionality as an extension for the projects pilet
	EnableTemplateManagement = 'enable-template-management', // Enables template management page (PCWA-2179)
	EnableTemplatedWorkflows = 'enable-workflow-templates', // enables templated workflows (PCWA-2047)
	EnableDocTemplateCreateEntry = 'enable_doc_template_create_menu', // enables the create document template entry for the create button on the management page (nojira)
	EnableRequestListSaveAsTemplateExtension = 'rl_enable_save_as_template_extension', // enables templated document requests (SFCC-2045)
	EnableTemplateDevTools = 'enable_template_dev_tools', // enables template-related dev tooling, such as template execution logs (PCWA-2558)
	EnableTemplateEditing = 'enable_template_editing', // enables template editing (PCWA-2673)
}
